import { toast } from 'react-toastify';

type ToastId = number | string;
/**
 * Adds a loading toast, which clears itself with timeout.
 * Returns a getter callback function, which returns the id of added toast.
 *
 * @param {string} message
 * @param {number}  time
 * @param {object}  options
 * @return { number | string} function
 */

export function addSelfClearingLoadingToast(
  message: string,
  time: number = 0,
  options: object = {}
): () => ToastId {
  const toastId = toast.loading(message, options);
  const timeout = setTimeout(() => {
    toast.dismiss(toastId);
  }, time);

  return (): ToastId => {
    clearTimeout(timeout);
    return toastId;
  };
}
