import React from 'react';

import { CloseIcon } from '../../components/icons';

const Chip = (theme) => {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />,
        variant: 'turquoise-60',
      },
      styleOverrides: {
        root: {
          fontSize: 13,
          fontWeight: 400,
          textTransform: 'capitalize',
          '& .MuiChip-deleteIcon': {
            margin: 0,
          },

          '&.MuiChip-turquoise-60': {
            color: theme.styles.chip['turquoise-60'].default.color,
            backgroundColor:
              theme.styles.chip['turquoise-60'].default.backgroundColor,
          },

          '&.MuiChip-turquoise-175': {
            color: theme.styles.chip['turquoise-175'].default.color,
            backgroundColor:
              theme.styles.chip['turquoise-175'].default.backgroundColor,
          },

          '&.MuiChip-outline-oxford-40': {
            color: theme.styles.chip['outline-oxford-40'].default.color,
            backgroundColor:
              theme.styles.chip['outline-oxford-40'].default.backgroundColor,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor:
              theme.styles.chip['outline-oxford-40'].default.borderColor,
          },

          '&.MuiChip-outline-oxford-40-thick': {
            color: theme.styles.chip['outline-oxford-40'].default.color,
            backgroundImage: ` repeating-linear-gradient(
              -45deg,
              #ffffff 0px,      /* White stripe start */
              #ffffff 10px,     /* White stripe end */
              #efefef 10px,     /* Gray stripe start */
              #efefef 20px      /* Gray stripe end */
            )
          `,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor:
              theme.styles.chip['outline-oxford-40'].default.borderColor,
          },

          '&.MuiChip-outline-red': {
            color: theme.styles.chip['outline-red'].default.color,
            backgroundColor:
              theme.styles.chip['outline-red'].default.backgroundColor,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.styles.chip['outline-red'].default.borderColor,
          },
          '&.MuiChip-outline-bold': {
            borderWidth: '2px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
          },

          '&.MuiChip-oxford-40': {
            color: theme.styles.chip['oxford-40'].default.color,
            backgroundColor:
              theme.styles.chip['oxford-40'].default.backgroundColor,
          },

          '&.MuiChip-green-100': {
            color: theme.styles.chip['green-100'].default.color,
            backgroundColor:
              theme.styles.chip['green-100'].default.backgroundColor,
          },

          '&.MuiChip-green-80': {
            color: theme.styles.chip['green-80'].default.color,
            backgroundColor:
              theme.styles.chip['green-80'].default.backgroundColor,
          },

          '&.MuiChip-turquoise-100': {
            color: theme.styles.chip['turquoise-100'].default.color,
            backgroundColor:
              theme.styles.chip['turquoise-100'].default.backgroundColor,
          },

          '&.MuiChip-forest': {
            color: theme.styles.chip['forest'].default.color,
            backgroundColor:
              theme.styles.chip['forest'].default.backgroundColor,
          },

          '&.MuiChip-blue': {
            color: theme.styles.chip['blue'].default.color,
            backgroundColor: theme.styles.chip['blue'].default.backgroundColor,
          },

          '&.MuiChip-orange': {
            color: theme.styles.chip['orange'].default.color,
            backgroundColor:
              theme.styles.chip['orange'].default.backgroundColor,
          },

          '&.MuiChip-red': {
            color: theme.styles.chip['red'].default.color,
            backgroundColor: theme.styles.chip['red'].default.backgroundColor,
          },

          '&.MuiChip-oxford-25': {
            color: theme.styles.chip['oxford-25'].default.color,
            backgroundColor:
              theme.styles.chip['oxford-25'].default.backgroundColor,
          },

          '&.MuiChip-oxford-10': {
            color: theme.styles.chip['oxford-10'].default.color,
            backgroundColor:
              theme.styles.chip['oxford-10'].default.backgroundColor,
          },

          '&.MuiChip-turquoise-150': {
            color: theme.styles.chip['turquoise-150'].default.color,
            backgroundColor:
              theme.styles.chip['turquoise-150'].default.backgroundColor,
          },
          '&.MuiChip-mixed': {
            color: theme.styles.iconBullet.mixed.default.color,
            backgroundColor:
              theme.styles.iconBullet.mixed.default.backgroundColor,
            backgroundImage: `url(${theme.styles.iconBullet.mixed.default.backgroundImage})`,
            backgroundSize: '22px 19px',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: theme.styles.iconBullet.mixed.default.borderColor,
          },
          '&.Mui-disabled ': {
            opacity: 1,
            color: theme.styles.chip['disabled'].default.color,
            backgroundColor:
              theme.styles.chip['disabled'].default.backgroundColor,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.styles.chip['disabled'].default.borderColor,
          },
        },
        sizeSmall: {
          height: 16,
          paddingLeft: 4,
          paddingRight: 4,
          ...theme.typography.tiny,
          lineHeight: '15px',
          '& .MuiChip-icon': {
            width: 12,
            height: 12,
            marginRight: 2,
            marginLeft: 2,
            color: 'inherit',
          },
          '& .MuiChip-label': {
            padding: 0,
            marginRight: 2,
            marginLeft: 2,
          },
          '& .MuiChip-deleteIcon': {
            width: 12,
            height: 12,
            marginRight: 2,
            marginLeft: 2,
            color: 'inherit',
          },
        },
        sizeMedium: {
          height: 20,
          paddingLeft: 6,
          paddingRight: 6,
          ...theme.typography.bodyRegularSmall,

          '& .MuiChip-icon': {
            width: 16,
            height: 16,
            color: 'inherit',
            marginRight: 2,
            marginLeft: 2,
          },
          '& .MuiChip-label': {
            padding: 0,
            marginRight: 2,
            marginLeft: 2,
          },
          '& .MuiChip-deleteIcon': {
            width: 16,
            height: 16,
            marginRight: 2,
            marginLeft: 2,
            color: 'inherit',
          },
        },
        sizeLarge: {
          height: 32,
          paddingLeft: 8,
          paddingRight: 8,
          ...theme.typography.bodyRegularSmall,

          '& .MuiChip-icon': {
            width: 20,
            height: 20,
            marginRight: 4,
            marginLeft: 4,
            color: 'inherit',
          },
          '& .MuiChip-label': {
            padding: 0,
            marginRight: 4,
            marginLeft: 4,
          },
          '& .MuiChip-deleteIcon': {
            width: 20,
            height: 20,
            marginRight: 4,
            marginLeft: 4,
            color: 'inherit',
          },
        },

        // outlined: {
        //   color: theme.styles.chip['outline-oxford-40'].default.borderColor,
        //   borderColor:
        //     theme.styles.chip['outline-oxford-40'].default.borderColor,
        // },
        // filledSecondary: {
        //   color: theme.styles.chip['turquoise-60'].default.color,
        //   backgroundColor:
        //     theme.styles.chip['turquoise-60'].default.backgroundColor,
        // },
        icon: {
          width: 20,
          height: 20,
          margin: 0,
        },
        iconSmall: {
          width: 16,
          height: 16,
        },
      },
    },
  };
};

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    outlined: true;
    filled: true;
    'turquoise-60': true;
    'turquoise-175': true;
    'outline-oxford-40': true;
    'outline-oxford-40-thick': true;
    'outline-red': true;
    'oxford-40': true;
    'green-100': true;
    'green-80': true;
    'turquoise-100': true;
    forest: true;
    blue: true;
    orange: true;
    red: true;
    'oxford-10': true;
    'turquoise-150': true;
    mixed: true;
  }
}

export default Chip;
