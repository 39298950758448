import { Dialog as MuiDialog } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDialogProps = ComponentProps<typeof MuiDialog>;

export const Dialog = forwardRef<HTMLDivElement, MuiDialogProps>(
  (props, ref) => <MuiDialog ref={ref} {...props} />
);

Dialog.displayName = 'Dialog';
