import { Chip as MuiChip } from '@mui/material';
import { ChipPropsVariantOverrides } from '@mui/material/Chip';
import { OverridableStringUnion } from '@mui/types';
import React, { ComponentProps, forwardRef } from 'react';

type MuiChipProps = ComponentProps<typeof MuiChip>;

interface ChipPropsOverrides {
  /**
   * The variant to use.
   * @default turquoise-60
   */
  variant?: OverridableStringUnion<
    | 'outlined'
    | 'filled'
    | 'turquoise-60'
    | 'turquoise-175'
    | 'outline-oxford-40'
    | 'outline-oxford-40-thick'
    | 'outline-red'
    | 'outline-bold'
    | 'oxford-40'
    | 'green-100'
    | 'green-80'
    | 'turquoise-100'
    | 'forest'
    | 'blue'
    | 'orange'
    | 'red'
    | 'oxford-25'
    | 'oxford-10'
    | 'turquoise-150'
    | 'mixed',
    ChipPropsVariantOverrides
  >;
}

type ChipProps = MuiChipProps & ChipPropsOverrides;

export const Chip = forwardRef<HTMLDivElement, ChipProps>((props, ref) => {
  return <MuiChip ref={ref} {...props} />;
});

Chip.displayName = 'Chip';
