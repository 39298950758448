import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Alert,
  Box,
  Card,
  CardContent,
  DocumentUploadField,
  LoadingButton,
  Stack,
} from '@dizzbo/ui';

import { addSelfClearingLoadingToast } from '@dizzbo/core';
import { useTranslation } from 'react-i18next';
import { Delivery } from './Delivery';
import { useUpdateProofOfDeliveryUpload } from './queries';

type Props = {
  order: OrderType;
  token: string;
};

export const OrderDetail: React.FC<Props> = ({ order, token }) => {
  const { mutateAsync } = useUpdateProofOfDeliveryUpload(token);
  const { t } = useTranslation();

  const { uuid: orderUUID, canUpload, pods } = order;
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      file: null,
      title: '',
      order: orderUUID,
    },
  });

  const onSubmit = async (formData) => {
    const data: { file: string; title: string; order: UUIDType } = {
      file: formData.file.file,
      title: formData.file.name,
      order: orderUUID,
    };
    const getToastId = addSelfClearingLoadingToast(t('uploadingCMR'), 6000);

    try {
      await mutateAsync(data);

      toast.update(getToastId(), {
        render: t('cmrUploaded'),
        autoClose: 6000,
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(getToastId(), {
        render: t('cmrCouldNotBeUploaded'),
        autoClose: 6000,
        type: 'error',
        isLoading: false,
      });
    }
  };

  return (
    <Box padding={6} paddingBottom={0}>
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          {order.deliveries.map((delivery) => (
            <Delivery key={delivery.uuid} delivery={delivery} />
          ))}
          <Card elevation={0} variant="filled-secondary">
            <CardContent>
              {!canUpload && pods[0]?.status === 'open' && (
                <Alert
                  severity="info"
                  title="CMR in review"
                  message="Your CMR upload was successfull and is waiting for review"
                />
              )}
              {!canUpload && pods[0]?.status === 'accepted' && (
                <Alert
                  severity="info"
                  title="CMR Accepted"
                  message="Your CMR is accepted by Dizzbo"
                />
              )}
              {canUpload && (
                <Controller
                  name="file"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DocumentUploadField
                      fileTypes={['PDF']}
                      {...field}
                      title="Select CMR"
                    />
                  )}
                />
              )}
            </CardContent>
          </Card>
        </Stack>
        <LoadingButton
          sx={{ marginTop: 6 }}
          size="large"
          type="submit"
          variant="primary"
          // loading={authMutation.isPending}
          disabled={!isDirty || !isValid}
        >
          Save
        </LoadingButton>
      </form>
    </Box>
  );
};
